import React, { useCallback, useState } from "react";
import {
  Card,
  CardHeader,
  CardActions,
  Box,
  Typography,
  useTheme,
  Divider,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import { IWorkorder, WorkStatus } from "@queue/types";
import { useWorkorderItems } from "../hooks/workitems";
import { WorkitemRow } from "./WorkitemRow";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { BrandAvatar } from "./BrandAvatar";
import { firestore } from "../firebase";
import { useFirestoreWriteBatch } from "@react-query-firebase/firestore";
import { doc, writeBatch, arrayUnion, arrayRemove } from "firebase/firestore";
import { LoadingButton } from "@mui/lab";

interface Props {
  order: IWorkorder;
  worklistId: string;
  width: number;
}

export const Workorder: React.FC<Props> = ({ order, worklistId, width }) => {
  const workitems = useWorkorderItems(order.id, worklistId);
  const theme = useTheme();

  const storeId = useAppSelector((state) => state.store.storeId);
  const [completing, setCompleting] = useState(false);
  const [selected, setSelected] = useState(false);

  const dispatch = useAppDispatch();

  const batch = writeBatch(firestore);

  const mutation = useFirestoreWriteBatch(batch);

  const handleCompleteOrder = useCallback(async () => {
    if (!selected) {
      setSelected(true);
      return;
    }

    setCompleting(true);
    if (worklistId) {
      const storeRef = doc(firestore, "stores", storeId);

      workitems?.data?.forEach((item: any) => {
        const wRef = doc(storeRef, "workitems", item.id);
        batch.update(wRef, { workstatus: WorkStatus.Completed });
      });

      const workorderRef = doc(storeRef, "workorders", order.id);
      batch.update(workorderRef, {
        pendingWIds: arrayRemove(worklistId),
        completedAt: arrayUnion({ worklistId, timestamp: new Date() }),
        completedIds: arrayUnion(worklistId),
      });

      mutation.mutate();
      return;
    }
  }, [order, dispatch, storeId, worklistId, workitems]);

  if (workitems.isLoading) {
    return null;
  }

  if (workitems?.data && workitems.data.length < 1) {
    return null;
  }

  return (
    <Box sx={{ width }}>
      <Card
        sx={{ margin: 0.5 }}
        style={{
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
      >
        <CardHeader
          avatar={<BrandAvatar source={order.source} />}
          titleTypographyProps={{ variant: "h4" }}
          title={order.orderNumber ? `# ${order.orderNumber}` : ""}
          sx={{ backgroundColor: theme.palette.info["500"] }}
          action={
            <Typography variant="h4">
              {order.externalOrderCode || order.name}
            </Typography>
          }
        />
        <Divider />
        <List sx={{ flex: 1 }}>
          {!!order.comment && (
            <>
              <ListItem>
                <ListItemText>{order.comment}</ListItemText>
              </ListItem>
              <Divider />
            </>
          )}
          {workitems?.data?.map((workitem) => {
            return (
              <WorkitemRow
                key={workitem.id}
                workitem={workitem}
                storeId={storeId}
              />
            );
          })}
        </List>
        <CardActions>
          <LoadingButton
            loading={completing}
            disabled={completing}
            onClick={handleCompleteOrder}
            size="large"
            style={{ flex: 1 }}
            variant={selected ? "contained" : "outlined"}
            color="success"
          >
            Ready
          </LoadingButton>
        </CardActions>
      </Card>
    </Box>
  );
};
