import { IWorkorder } from "@queue/types";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
  collection,
  CollectionReference,
  query,
  where,
} from "firebase/firestore";
import { AppState } from "reducers";
import { selectStoreId } from "selectors/store";
import { firestore } from "../firebase";
import { useAppSelector } from "./redux";
import sortBy from "lodash/sortBy";

const selectWorkordersRef = (state: AppState) => {
  const storeId = selectStoreId(state);

  return collection(
    firestore,
    "stores",
    storeId,
    "workorders"
  ) as CollectionReference<IWorkorder>;
};

export const useCompletedWorkorders = (worklistId: string) => {
  const workordersRef = useAppSelector(selectWorkordersRef);

  console.log({ worklistId });

  const workordersQuery = query(
    workordersRef,
    where("completedIds", "array-contains", worklistId)
  );

  return useFirestoreQueryData(
    ["completedWorkorders", worklistId],
    workordersQuery,
    {
      idField: "id",
    },
    {
      select: (workorders) =>
        sortBy(workorders, (wo) => {
          const completedAt = wo.completedAt?.find(
            (cwo) => cwo.worklistId === worklistId
          );
          return completedAt?.timestamp.toDate();
        }).reverse(),
    }
  );
};
