import React, { useCallback } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { userSlice } from "../redux/user";
import { Typography, Button, AppBar, Toolbar, IconButton } from "@mui/material";
import logo from "../img/logo.png";
import { useAppDispatch } from "../hooks/redux";
import { storeSlice } from "../redux/store";
import { useDatabaseValue } from "@react-query-firebase/database";
import { database, auth } from "../firebase";
import { ref } from "firebase/database";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  storeId: string;
}

const titleMap: any = {
  "/worklist_group_orders": "Grouped by brand",
  "/worklist_orders": "Grouped by order",
};

export const Header: React.FC<Props> = ({ storeId }) => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const storeNameRef = ref(database, `/branches/${storeId}/settings/name`);
  const storeName = useDatabaseValue(["stores", storeId], storeNameRef, {
    subscribe: true,
  });

  const handleGoHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleSignOut = useCallback(async () => {
    dispatch(storeSlice.actions.setStoreId(""));
    dispatch(storeSlice.actions.signOut());
    auth.signOut();
    navigate("/");
  }, [dispatch, navigate]);

  const handleToggleDarkmode = useCallback(() => {
    dispatch(userSlice.actions.toggleDarkmode());
  }, [dispatch]);

  return (
    <>
      <AppBar position="absolute">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleGoHome}
            sx={{ marginRight: 2 }}
          >
            <img src={logo} height={40} alt="QUEUE" />
          </IconButton>
          <Typography
            variant={"h6"}
            onClick={handleGoHome}
            sx={{ flexGrow: 1 }}
          >
            {(storeName?.data || "") +
              " " +
              (titleMap[location?.pathname]
                ? " | " + titleMap[location.pathname]
                : "")}
          </Typography>
          <IconButton onClick={handleToggleDarkmode} color="inherit">
            <Brightness4Icon />
          </IconButton>
          <Button color="inherit" onClick={handleSignOut}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
