import { Stack } from "@mui/material";
import { IWorkorder } from "@queue/types";
import { GroupedWorklistHeader } from "components/GroupedWorklistHeader";
import React from "react";
import useDimensions from "react-cool-dimensions";
import { FullScreenHandle } from "react-full-screen";
import { useWorklistOrders } from "../../hooks/orders";
import { calculateBestWidth } from "../WorklistHeader";
import { Workorder } from "../Workorder";

interface Props {
  worklistId: string;
  handleFullscreen: FullScreenHandle;
}

export const WorklistGroup: React.FC<Props> = ({
  worklistId,
  handleFullscreen,
}) => {
  const orders = useWorklistOrders({ worklistId }) as any;

  const { observe, width } = useDimensions();

  const itemWidth = calculateBestWidth(width, 300);

  return (
    <div
      ref={observe}
      style={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GroupedWorklistHeader
        worklistId={worklistId}
        handleFullscreen={handleFullscreen}
      />

      <Stack direction="row" flexWrap="wrap" sx={{ display: "flex" }}>
        {orders.data?.map((order: IWorkorder) => (
          <Workorder
            order={order}
            worklistId={worklistId}
            key={order.id}
            width={itemWidth}
          />
        ))}
      </Stack>
    </div>
  );
};
