import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Card, CardHeader, Dialog } from "@mui/material";
import { useWorklist } from "hooks/useWorklists";
import React, { useState } from "react";
import { FullScreenHandle } from "react-full-screen";
import { WorkorderActivities } from "./activities/WorkorderActivities";
import BackupTableIcon from "@mui/icons-material/BackupTable";
interface Props {
  worklistId: string;
  handleFullscreen: FullScreenHandle;
}

export const GroupedWorklistHeader: React.FC<Props> = ({
  worklistId,
  handleFullscreen,
}) => {
  const worklist = useWorklist(worklistId);
  // const toggleFullscreen = useCallback(() => {
  //   if (handleFullscreen.active) {
  //     handleFullscreen.exit();
  //   } else {
  //     handleFullscreen.enter();
  //   }
  // }, [handleFullscreen]);

  const [showHistory, setShowHistory] = useState(false);

  return (
    <>
      <Dialog
        open={showHistory}
        onClose={() => setShowHistory(false)}
        fullWidth
        maxWidth="lg"
      >
        {showHistory && worklist.data && (
          <WorkorderActivities worklist={worklist.data} />
        )}
      </Dialog>

      <Card
        style={{
          margin: 10,
          height: 70,
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
      >
        <CardHeader
          titleTypographyProps={{ variant: "h2" }}
          title={worklist?.data?.name || "Default"}
          action={
            <Button
              endIcon={<BackupTableIcon fontSize={"large"} />}
              onClick={() => setShowHistory(true)}
            >
              Log
            </Button>
          }
        />
      </Card>
    </>
  );
};
