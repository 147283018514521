import { createSlice } from "@reduxjs/toolkit";
import { IWorkitem } from "@queue/types";

interface State {
  kdsInstructions: IWorkitem | null;
  hideHeader: boolean;
}

const initialState: State = {
  kdsInstructions: null,
  hideHeader: false,
};

export const displaySlice = createSlice({
  name: "display",
  initialState,
  reducers: {
    setKdsInstructions: (state, { payload }) => {
      state.kdsInstructions = payload;
    },
    toggleHeader: (state) => {
      state.hideHeader = !state.hideHeader;
    },
  },
});
