import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { displaySlice } from "../redux/display";
import { useDatabaseValue } from "@react-query-firebase/database";
import { database } from "../firebase";
import { ref } from "firebase/database";
import { IWorkitem } from "@queue/types";

interface Props {
  workitem: IWorkitem;
}

export const Instructions: React.FC<Props> = ({ workitem }) => {
  const dispatch = useAppDispatch();
  const storeId = useAppSelector((state) => state.store.storeId);

  const instructionsRef = ref(
    database,
    `/branches/${storeId}/menu/instructions/${workitem.variant}`
  );

  const instructions = useDatabaseValue(
    ["instructions", workitem.variant],
    instructionsRef
  );

  if (instructions.isLoading) {
    return null;
  }

  return null;

  return (
    <Dialog
      open={!!workitem}
      onClose={() => dispatch(displaySlice.actions.setKdsInstructions(null))}
    >
      <DialogTitle>{workitem.productName}</DialogTitle>
      <DialogContent>
        <Typography display="inline" style={{ whiteSpace: "pre-line" }}>
          {instructions}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            dispatch(displaySlice.actions.setKdsInstructions(null))
          }
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
