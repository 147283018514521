import { useAppSelector } from "hooks/redux";
import { useWorkitems } from "hooks/workitems";
import React, { useMemo } from "react";
import useDimensions from "react-cool-dimensions";
import FlexView from "react-flexview";
import { FullScreenHandle } from "react-full-screen";
import { selectSelectedworkitemId } from "selectors/store";
import { Workitem } from "./Workitem";
import { calculateBestWidth, WorklistHeader } from "./WorklistHeader";

interface Props {
  worklistId: string;
  handleFullscreen: FullScreenHandle;
}

export const Worklist: React.FC<Props> = ({ worklistId, handleFullscreen }) => {
  const workitems = useWorkitems(worklistId);

  const selectedWorkitemId = useAppSelector(selectSelectedworkitemId);

  const { observe, width: screenWidth } = useDimensions();

  const itemWidth = useMemo(
    () => calculateBestWidth(screenWidth, 250),
    [screenWidth]
  );

  return (
    <div
      ref={observe}
      style={{ flex: 1, display: "flex", flexDirection: "row" }}
    >
      <WorklistHeader
        worklistId={worklistId}
        handleFullscreen={handleFullscreen}
        worklistCount={workitems?.data?.length || 0}
      >
        <FlexView wrap>
          {workitems?.data?.map((workitem) => {
            return (
              <div
                key={workitem.id}
                style={{ width: itemWidth - 1, padding: 3 }}
              >
                <Workitem
                  workitem={workitem}
                  selectedWorkitemId={selectedWorkitemId}
                />
              </div>
            );
          })}
        </FlexView>
      </WorklistHeader>
    </div>
  );
};
