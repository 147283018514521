import React from "react";
import { useWorklistGroups } from "../hooks/useWorklists";
import { useNavigate } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
// import ListAltIcon from "@mui/icons-material/ListAlt";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import {
  Card,
  CardHeader,
  Container,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import { sortBy } from "lodash";
import FlexView from "react-flexview";
import { storeSlice } from "../redux/store";

import { useAppDispatch } from "../hooks/redux";
// import { selectCloudCenterId } from "../selectors/store";

export const Home: React.FC = () => {
  const worklistGroups = useWorklistGroups();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // const cloudCenterId = useAppSelector(selectCloudCenterId);

  if (worklistGroups.isLoading) {
    return (
      <Stack
        sx={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={40} />
      </Stack>
    );
  }

  if (!worklistGroups?.data) {
    return (
      <Container
        sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h4">No Worklist Groups Configured</Typography>
      </Container>
    );
  }

  return (
    <div>
      <Container sx={{ flex: 1 }}>
        <FlexView column style={{ flexGrow: 1 }}>
          {sortBy(worklistGroups?.data, (wg) => wg.name).map(
            (worklistGroup, worklistGroupId) => {
              return (
                <FlexView
                  key={"items" + worklistGroupId}
                  hAlignContent="center"
                  vAlignContent="center"
                >
                  <Typography variant={"h5"} sx={{ width: 200 }}>
                    {worklistGroup.name}
                  </Typography>
                  <Card
                    sx={{ flex: 1, margin: 2 }}
                    onClick={() => {
                      dispatch(
                        storeSlice.actions.setWorklistGroup(worklistGroup)
                      );
                      navigate(`/worklist_group`, {
                        state: {
                          title: "Items per worklist",
                        },
                      });
                    }}
                  >
                    <CardHeader
                      title={`Items per worklist`}
                      subheader="One card per work item"
                      avatar={<ListIcon />}
                    />
                  </Card>

                  <Card
                    sx={{ flex: 1, margin: 2 }}
                    key={"orders" + worklistGroup.id}
                    onClick={() => {
                      dispatch(
                        storeSlice.actions.setWorklistGroup(worklistGroup)
                      );
                      navigate(`/worklist_orders`, {
                        state: {
                          title: "Grouped by order",
                        },
                      });
                    }}
                  >
                    <CardHeader
                      avatar={<DeveloperBoardIcon />}
                      title={`Grouped by order`}
                      subheader="Multiple cards per brand"
                    />
                  </Card>

                  {/* {cloudCenterId && ( */}
                  {/*   <Card */}
                  {/*     sx={{ flex: 1, margin: 2 }} */}
                  {/*     onClick={() => { */}
                  {/*       dispatch( */}
                  {/*         storeSlice.actions.setWorklistGroup(worklistGroup) */}
                  {/*       ); */}
                  {/*       navigate(`/worklist_group_orders`); */}
                  {/*     }} */}
                  {/*   > */}
                  {/*     <CardHeader */}
                  {/*       title={`Grouped by brand`} */}
                  {/*       subheader="One card per brand" */}
                  {/*       avatar={<ListAltIcon />} */}
                  {/*     /> */}
                  {/*   </Card> */}
                  {/* )} */}
                </FlexView>
              );
            }
          )}
        </FlexView>
      </Container>
    </div>
  );
};
