import {
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IWorklist, IWorkorder } from "@queue/types";
import dayjs from "dayjs";
import { useCompletedWorkorders } from "hooks/workorders";
import React, { useState } from "react";
import { CompletedWorkorder } from "./CompletedWorkorder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface Props {
  worklist: IWorklist;
}

export const WorkorderActivities: React.FC<Props> = ({ worklist }) => {
  const workorders = useCompletedWorkorders(worklist.id);

  const [showOrder, setShowOrder] = useState<IWorkorder | null>(null);

  if (!!showOrder) {
    return (
      <CompletedWorkorder
        workorder={showOrder}
        worklistId={worklist.id}
        setShowOrder={setShowOrder}
      />
    );
  }

  return (
    <>
      <DialogTitle>Completed orders</DialogTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 180 }}>Completed at</TableCell>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {workorders?.data?.map((workorder) => {
            const completedAt = workorder?.completedAt?.find(
              (w) => w.worklistId === worklist.id
            );
            return (
              <TableRow
                key={workorder.id}
                onClick={() => setShowOrder(workorder)}
              >
                <TableCell sx={{ width: 180 }}>
                  {dayjs(completedAt?.timestamp.toDate()).format("MM/DD HH:mm")}
                </TableCell>
                <TableCell>{workorder.name}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <ArrowForwardIosIcon />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
