import {
  Button,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { IWorkorder } from "@queue/types";
import { useWorkorderItems } from "hooks/workitems";
import React from "react";

interface Props {
  workorder: IWorkorder;
  worklistId: string;
  setShowOrder: any;
}

export const CompletedWorkorder: React.FC<Props> = ({
  workorder,
  worklistId,
  setShowOrder,
}) => {
  const workitems = useWorkorderItems(workorder.id, worklistId);

  console.log({ workitems });
  return (
    <>
      <DialogTitle>{workorder.name}</DialogTitle>
      <Table>
        <TableBody>
          {workitems.data?.map((workitem) => {
            return (
              <TableRow key={workitem.id}>
                <TableCell sx={{ width: 180 }}>
                  {dayjs(workitem.completedAt).format("MM-DD HH:mm")}
                </TableCell>
                <TableCell>{`${workitem.quantity} x ${workitem.productName}`}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <DialogActions>
        <Button onClick={() => setShowOrder(null)}>Back</Button>
      </DialogActions>
    </>
  );
};
