import React, { useCallback } from "react";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Chip,
  Card,
  CardHeader,
  CardActions,
  Typography,
  Button,
  Avatar,
  Stack,
  Divider,
} from "@mui/material";
import { IWorkitem, OrderSourceType } from "@queue/types";
import { Colors } from "../colors";
import Timeago from "react-timeago";
import FlexView from "react-flexview";
import foodpanda from "../img/panda.png";
import uber from "../img/uber.jpg";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { storeSlice } from "../redux/store";
import { displaySlice } from "../redux/display";
import { firestore } from "../firebase";
import { doc } from "firebase/firestore";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { deepOrange } from "@mui/material/colors";
import _ from "lodash";
import MessageIcon from "@mui/icons-material/Message";
import { selectStoreId } from "selectors/store";

interface Props {
  workitem: IWorkitem;
  selectedWorkitemId?: string | null;
}

export const Workitem: React.FC<Props> = ({ workitem, selectedWorkitemId }) => {
  const storeId = useAppSelector(selectStoreId);

  const itemSelected = workitem.id === selectedWorkitemId;

  const dispatch = useAppDispatch();
  const workitemRef = doc(
    firestore,
    "stores",
    storeId,
    "workitems",
    workitem.id
  );

  const mutation = useFirestoreDocumentMutation(workitemRef, { merge: true });

  const handleSelect = useCallback(() => {
    if (itemSelected) {
      mutation.mutate({
        staff: "kds",
        completedAt: Date.now(),
        status: 1,
        workstatus: "COMPLETED",
        workitem,
      });
    } else {
      dispatch(storeSlice.actions.setSelectedWorkitemId(workitem.id));
    }
  }, [dispatch, itemSelected, workitem, mutation]);

  const handleShowInstruction = useCallback(() => {
    dispatch(displaySlice.actions.setKdsInstructions(workitem));
  }, [workitem, dispatch]);

  const colorIndex = workitem.orderNumber ? workitem.orderNumber % 23 : 0;
  const color = Colors[colorIndex];

  let name = workitem.productName;

  if (workitem.variantName) {
    name = `${name} ${workitem.variantName}`;
  }

  let subheader = "";

  if (workitem.parentName) {
    subheader = `${workitem.parentName}`;
  }

  subheader = `${subheader} | ${
    workitem?.externalOrderCode || workitem.orderName
  }`;
  return (
    <Card
      sx={{
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        flex: 1,
        flexGrow: 1,
      }}
      style={{ border: `8px solid ${color}` }}
    >
      <CardHeader
        style={{ padding: 10, paddingBottom: 0 }}
        title={name}
        titleTypographyProps={{ variant: "h5", fontWeight: "bolder" }}
        subheader={
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between", marginRight: 0.9 }}
          >
            <Typography>{workitem.parentName || ""}</Typography>
            <Typography>
              {workitem.externalOrderCode || workitem.orderName || ""}
            </Typography>
          </Stack>
        }
        avatar={
          <Avatar
            sx={{ backgroundColor: deepOrange[600], fontWeight: "bolder" }}
            variant="rounded"
          >
            {workitem.quantity}
          </Avatar>
        }
        action={
          <>
            {workitem.source === OrderSourceType.UBER && (
              <img src={uber} height={40} alt={""} />
            )}
            {workitem.source === OrderSourceType.DELIVERY_HERO && (
              <img src={foodpanda} height={40} alt={""} />
            )}
          </>
        }
      />
      <Divider />
      {workitem.parentComment && (
        <>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <MessageIcon sx={{ marginLeft: 1, marginRight: 1 }} />
            <Typography sx={{ fontSize: 18 }}>{workitem.parentComment}</Typography>
          </Stack>
          <Divider />
        </>
      )}
      {workitem.comment && (
        <>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <MessageIcon sx={{ marginLeft: 1, marginRight: 1 }} />
            <Typography sx={{ fontSize: 18 }}>{workitem.comment}</Typography>
          </Stack>
          <Divider />
        </>
      )}

      {workitem.productOptions && (
        <>
          {_.map(workitem.productOptions, (productOption, id) => {
            const quantity = productOption.quantity;
            let name = productOption.name;

            if (productOption.subName) {
              name = name + " | " + productOption.subName;
            }

            const displayName = `${quantity} x ${name}`;

            return (
              <Chip
                key={id}
                style={{ margin: 5, padding: 20 }}
                label={<Typography variant="h6">{displayName}</Typography>}
              />
            );
          })}
        </>
      )}

      <FlexView
        style={{
          justifyContent: "space-between",
          flex: 1,
          paddingRight: 10,
          paddingLeft: 10,
        }}
      >
        <Typography>
          <Timeago date={workitem.createdAt} />
        </Typography>
        <Typography>
          {`${workitem.brandName || ""} ${_.padStart(
            workitem.orderNumber + "",
            4,
            "0"
          )}`}
        </Typography>
      </FlexView>
      <CardActions>
        {/* {workitem.kdsInst && ( */}
        {/*   <Button */}
        {/*     startIcon={<HelpOutlineIcon />} */}
        {/*     onClick={handleShowInstruction} */}
        {/*   > */}
        {/*     Help */}
        {/*   </Button> */}
        {/* )} */}
        <Button
          sx={{ flex: 1 }}
          variant={itemSelected ? "contained" : "outlined"}
          color={"success"}
          onClick={handleSelect}
        >
          DONE
        </Button>
      </CardActions>
    </Card>
  );
};
