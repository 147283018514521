import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
import { Provider } from "react-redux";
import Login from "./components/Login";
import { Home } from "./components/Home";
import { WorklistGroup } from "./components/WorklistGroup";
import SelectStore from "./components/SelectStore";
import { PersistGate } from "redux-persist/integration/react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { brandingDarkTheme, brandingLightTheme } from "./theme";
import { useAuthUser } from "@react-query-firebase/auth";
import { selectStoreId } from "./selectors/store";
import { Sync } from "./components/Sync";
import store from "./store";
import { persistStore } from "redux-persist";
// import { WorklistGroupOrders } from "./components/byBrand/WorklistGroupOrders";
import { WorklistOrders } from "./components/byWorklist/WorklistOrders";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { storeSlice } from "./redux/store";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Header } from "./components/Header";
import { userSlice } from "./redux/user";
import { Instructions } from "./components/Instructions";
import { auth } from "./firebase";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from "react-ga4";

ReactGA.initialize("G-5HP705E71C");

export const persistor = persistStore(store);

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Screen />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

const Screen: React.FC = () => {
  const dispatch = useAppDispatch();
  const handleFullscreen = useFullScreenHandle();
  const user = useAuthUser(["user"], auth);

  const storeId = useAppSelector(selectStoreId);

  const storeIds = useAppSelector((state) => state.user.storeIds);

  const darkmode = useAppSelector((state) => state.user.darkmode);

  useEffect(() => {
    const syncStoreIds = async () => {
      if (user.data) {
        try {
          const token = await user.data.getIdTokenResult();

          const storeIds = (token?.claims?.storeIds || []) as any[];

          if (storeIds?.length < 1) {
            throw new Error("Missing stores");
          }

          if (storeIds.length > 1) {
            dispatch(userSlice.actions.setStoreIds(storeIds));
          } else {
            const newStoreId = storeIds[0];

            dispatch(storeSlice.actions.setStoreId(newStoreId));
          }
        } catch (error) {}
      }
    };
    syncStoreIds();
  }, [user?.data, dispatch]);

  const selectedWorkitem = useAppSelector(
    (state) => state.display.kdsInstructions
  );

  const hideHeader = useAppSelector((state) => state.display.hideHeader);

  if (!user?.isLoading && !user?.data) {
    return <Login />;
  }

  if (user?.data && !storeId && storeIds) {
    return <SelectStore storeIds={storeIds} />;
  }

  if (!storeId) {
    return null;
  }

  if (user?.isLoading) {
    return null;
  }

  return (
    <ThemeProvider theme={darkmode ? brandingDarkTheme : brandingLightTheme}>
      <CssBaseline />
      <Router>
        {!hideHeader && <Header storeId={storeId} />}
        {!!selectedWorkitem && <Instructions workitem={selectedWorkitem} />}
        <Sync storeId={storeId} />

        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="/worklist_group"
            element={
              <FullScreen handle={handleFullscreen}>
                <WorklistGroup handleFullscreen={handleFullscreen} />
              </FullScreen>
            }
          />

          <Route
            path="/worklist_orders"
            element={
              <FullScreen handle={handleFullscreen}>
                <WorklistOrders handleFullscreen={handleFullscreen} />
              </FullScreen>
            }
          />

          {/* <Route */}
          {/*   path="/worklist_group_orders" */}
          {/*   element={ */}
          {/*     <FullScreen handle={handleFullscreen}> */}
          {/*       <WorklistGroupOrders handleFullscreen={handleFullscreen} /> */}
          {/*     </FullScreen> */}
          {/*   } */}
          {/* /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
