import { IOrder } from "@queue/types";
import { useAppSelector } from "./redux";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
  collection,
  where,
  orderBy,
  query,
  CollectionReference,
} from "firebase/firestore";
import { firestore } from "../firebase";
import _ from "lodash";

interface Params {
  worklistId?: string;
}

export const useOrders = ({ worklistId }: Params) => {
  const storeId = useAppSelector((state) => state.store.storeId);

  const ordersRef = collection(
    firestore,
    "stores",
    storeId,
    "openItems",
    "cart",
    "openOrders"
  ) as CollectionReference<IOrder>;

  let ordersQuery = query(ordersRef, orderBy("createdAt"));

  if (worklistId) {
    ordersQuery = query(
      ordersRef,
      orderBy("createdAt"),
      where("kdsId", "==", worklistId)
    );
  } else {
  }

  return useFirestoreQueryData(
    ["worklistOrders", worklistId],
    ordersQuery,
    {
      idField: "id",
      subscribe: true,
    },
    { cacheTime: 0 }
  );
};

export const useWorklistOrders = ({
  worklistId = "default",
}: {
  worklistId: string;
}) => {
  const storeId = useAppSelector((state) => state.store.storeId);

  const workordersRef = collection(firestore, "stores", storeId, "workorders");

  const workordersQuery = query(
    workordersRef,
    where("pendingWIds", "array-contains", worklistId)
  );

  return useFirestoreQueryData(
    ["workorders", worklistId],
    workordersQuery,
    { idField: "id", subscribe: true },
    {
      cacheTime: 0,
      select: (orders) => _.sortBy(orders, "createdAt"),
      notifyOnChangeProps: ["data", "error"],
    }
  );
};
