import { IWorklist } from "@queue/types";
import { useDatabaseValue } from "@react-query-firebase/database";
import { ref } from "firebase/database";
import { useSelector } from "react-redux";
import { database } from "../firebase";
import { AppState } from "../reducers";

export const useWorklistGroups = () => {
  const storeId = useSelector((state: AppState) => state.store.storeId);

  const worklistsRef = ref(
    database,
    `branches/${storeId}/worklistGroups`
  ) as any;

  return useDatabaseValue(["worklistGroups"], worklistsRef) as any;
};

export const useWorklist = (worklistId: string) => {
  const storeId = useSelector((state: AppState) => state.store.storeId);
  const worklistRef = ref(
    database,
    `branches/${storeId}/worklists/${worklistId}`
  );

  return useDatabaseValue<IWorklist>(["worklist", worklistId], worklistRef);
};
