export const Colors = [
  "#F44336",
  "#E1BEE7",
  "#1565C0",
  "#AB47BC",
  "#4A148C",
  "#FBC02D",
  "#3F51B5",
  "#B2EBF2",
  "#00838F",
  "#1A237E",
  "#00B8D4",
  "#004D40",
  "#F57F17",
  "#00BFA5",
  "#2E7D32",
  "#FFF176",
  "#9CCC65",
  "#33691E",
  "#E6EE9C",
  "#827717",
  "#F9A825",
  "#FFD600",
  "#6D4C41",
  "#BDBDBD",
];
