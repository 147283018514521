import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../reducers";
import { doc, collection } from "firebase/firestore";
import { firestore } from "../firebase";

export const selectStoreId = (state: AppState) => {
  return state.store.storeId;
};

export const selectStoreRef = createSelector([selectStoreId], (storeId) =>
  doc(firestore, "stores", storeId)
);

export const selectCartRef = createSelector([selectStoreRef], (storeRef) =>
  doc(storeRef, "openItems", "cart")
);

export const selectWorkitemsRef = createSelector([selectStoreRef], (storeRef) =>
  collection(storeRef, "workitems")
);

export const selectCloudCenterId = (state: AppState) =>
  state.store.cloudCenterId;

// export const selectCloudCenterref = createSelector(
//   [selectCloudCenterId],
//   (cloudCenterId) =>
//     firebase.firestore().collection("cloudCenters").doc(cloudCenterId)
// );

export const selectWorklistGroup = (state: AppState) =>
  state.store.worklistGroup;

export const selectSelectedworkitemId = (state: AppState) =>
  state.store.selectedWorkitemId;
