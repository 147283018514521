import { useWorklist } from "hooks/useWorklists";
import { Card, Typography, CardHeader } from "@mui/material";
import React, { useCallback } from "react";
import FlexView from "react-flexview/lib";
import { useDispatch } from "react-redux";
import { displaySlice } from "redux/display";

interface Props {
  worklistId: string;
  handleFullscreen: any;
}

export const calculateBestWidth = (width: number, size: number) => {
  if (!size) {
    return 0;
  }

  const itemCount = Math.floor(width / size);

  // Return the width if it can hold less than a
  // single card
  if (!itemCount) {
    return size;
  }
  return Math.round(width / itemCount);
};

interface Props {
  worklistId: string;
  handleFullscreen: any;
  worklistCount: number;
}

export const WorklistHeader: React.FC<Props> = ({
  worklistId,
  children,
  handleFullscreen,
  worklistCount,
}) => {
  const worklist = useWorklist(worklistId);

  const dispatch = useDispatch();

  const toggleFullscreen = useCallback(() => {
    dispatch(displaySlice.actions.toggleHeader());
    // if (handleFullscreen.active) {
    //   handleFullscreen.exit();
    // } else {
    //   handleFullscreen.enter();
    // }
  }, [dispatch]);

  return (
    <FlexView column style={{ width: "100%" }}>
      <Card
        onClick={toggleFullscreen}
        style={{
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          display: "flex",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 10,
          justifyContent: "space-between",
        }}
      >
        <CardHeader title={worklist?.data?.name || "Default"} />
        <Typography variant="h3" style={{ margin: 5 }}>
          {worklistCount}
        </Typography>
      </Card>
      <>{children}</>
    </FlexView>
  );
};
