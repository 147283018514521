import { useAppSelector } from "./redux";
import { IWorkitem, WorkStatus } from "@queue/types";
import { firestore } from "../firebase";
import sortBy from "lodash/sortBy";
import {
  where,
  query,
  collection,
  CollectionReference,
  orderBy,
  limit,
} from "firebase/firestore";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import _ from "lodash";
import { AppState } from "reducers";

const selectStoreId = (state: AppState) => state.store.storeId;

const selectWorkitemsRef = (state: AppState) => {
  const storeId = selectStoreId(state);

  return collection(
    firestore,
    "stores",
    storeId,
    "workitems"
  ) as CollectionReference<IWorkitem>;
};

type ResponseData = {
  status: string;
  data: IWorkitem[];
};

export const useWorkitems = (worklistId = "default") => {
  const workitemsRef = useAppSelector(selectWorkitemsRef);
  const itemsQuery = query(
    workitemsRef,
    where("worklist", "==", worklistId),
    where("workstatus", "==", WorkStatus.Open),
    limit(50),
    orderBy("createdAt")
  );

  //@ts-ignore
  return useFirestoreQueryData(
    ["workitems", worklistId],
    itemsQuery,
    {
      idField: "id",
      subscribe: true,
    },
    {
      cacheTime: 0,
      notifyOnChangeProps: ["data", "error"],
      select(response: ResponseData) {
        return sortBy(response, ["createdAt", "sortKey"]);
      },
    }
  );
};

export const useWorkorderItems = (orderId: string, worklistId: string) => {
  const workitemsRef = useAppSelector(selectWorkitemsRef);

  const itemsQuery = query(
    workitemsRef,
    where("order", "==", orderId),
    where("worklist", "==", worklistId)
  );

  return useFirestoreQueryData(
    ["workitems", orderId, worklistId],
    itemsQuery,
    {
      subscribe: true,
      idField: "id",
    },
    {
      select: (data) => _.sortBy(data, "sortKey"),
      cacheTime: 0,
    }
  );
};

export const useCompletedItems = (worklistId: string) => {
  const workitemsRef = useAppSelector(selectWorkitemsRef);

  const itemsQuery = query(
    workitemsRef,
    where("worklist", "==", worklistId),
    limit(20),
    orderBy("completedAt", "desc")
  );

  return useFirestoreQueryData(["completedWorkitems", worklistId], itemsQuery, {
    idField: "id",
  });
};
